<template>
  <div class="area--list mt-5">
    <div class="official--partner-area-header">
      <h2 class="title--text">
        {{ $t('officialPartner.partnerArea') }}
      </h2>
      <!--      <rent-sell-switch-->
      <!--        :listingType="listingType"-->
      <!--        :isHomepage="true"-->
      <!--        @changeType="changeType"-->
      <!--      ></rent-sell-switch>-->
    </div>
    <div class="official--partner-area-wrapper" v-if="areas && areas.length > 0">
      <div v-for="area in areas" :key="area.uuid" class="area--item">
        <card-official-partner :officialPartner="area" :allowMultiImage="false" :key="area.uuid" />
      </div>
    </div>
    <pagination
      v-if="areaMeta"
      :push-state="false"
      :meta="areaMeta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      :useRouter="false"
    />
  </div>
</template>

<script>
// const RentSellSwitch = () => import('@/components/utils/rent-sell-switch');
const CardOfficialPartner = () => import('@/components/utils/card-official-partner');
const Pagination = () => import('@/components/utils/fractal-pagination');
export default {
  components: {
    // RentSellSwitch,
    CardOfficialPartner,
    Pagination,
  },
  props: {
    areas: {
      type: Array,
    },
    areaMeta: {
      type: Object,
    },
    partnerDetail: {
      required: true,
    },
  },
  data: () => ({
    page: 1,
    listingType: 'R',
  }),
  methods: {
    changeType(newType) {
      this.listingType = newType;
      this.getAreaList();
    },
    changePage(nextPage) {
      this.page = nextPage;
      this.getAreaList();
    },
    async getAreaList() {
      await this.$store.dispatch('v2/officialPartner/getAreaList', {
        listingType: this.listingType,
        partnerUuid: this.partnerDetail.uuid,
        page: this.page,
      });
    },
  },
};
</script>
